import React from "react"
import { getTextColor, Target } from "../../../utils"

type Props = {
  items: {
    title: string
    path: string
  }[]
  target: Target
}

const Assessment: React.VFC<Props> = ({ items, target }) => {
  return (
    <ul className=" min-h-[250px]">
      {items.map(item => {
        return (
          <div key={item.title}>
            <li className="flex justify-between py-4">
              <div>{item.title}</div>
              <a
                href={item.path}
                target="_blank"
                className={`ml-2 font-semibold flex-shrink-0 ${getTextColor(
                  target
                )}`}
              >
                ダウンロード
              </a>
            </li>
            <div className="border-dash"></div>
          </div>
        )
      })}
    </ul>
  )
}

export default Assessment
